import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { StoreExternalApiResponse } from './before/store-external-api-response';

import PaymentMethodIdentityVerificationResult from '@/pages/payment-method-identity-verification-result.vue';
import PlatformCancelCompleted from '@/pages/platform/cancel/completed.vue';
import PlatformCancelConfirm from '@/pages/platform/cancel/confirm.vue';
import PlatformCancelGuide from '@/pages/platform/cancel/guide.vue';
import PlatformCancelImpossible from '@/pages/platform/cancel/impossible.vue';
import PlatformEntryAcceptEmail from '@/pages/platform/entry/accept-email.vue';
import PlatformEntryCompleted from '@/pages/platform/entry/completed.vue';
import PlatformEntryConfirm from '@/pages/platform/entry/confirm.vue';
import PlatformEntryInputEmail from '@/pages/platform/entry/input-email.vue';
import PlatformEntryInput from '@/pages/platform/entry/input.vue';
import PlatformEntrySelectPortasService from '@/pages/platform/entry/select-portas-service.vue';
import PlatformIndex from '@/pages/platform/index.vue';
import PlatformMaintenance from '@/pages/platform/maintenance.vue';
import MyPageBillingHistoryList from '@/pages/platform/my-page/billing-history/list.vue';
import MyPageIndex from '@/pages/platform/my-page/index.vue';
import MyPageMemberCompleted from '@/pages/platform/my-page/member-completed.vue';
import MyPageMemberConfirm from '@/pages/platform/my-page/member-confirm.vue';
import MyPageMemberEdit from '@/pages/platform/my-page/member-edit.vue';
import MyPagePaymentMethodDeleteCompleted from '@/pages/platform/my-page/payment-methods/delete-completed.vue';
import MyPagePaymentMethodDeleteConfirm from '@/pages/platform/my-page/payment-methods/delete-confirm.vue';
import MyPagePaymentMethodEdit from '@/pages/platform/my-page/payment-methods/edit.vue';
import MyPagePaymentMethodInputCompleted from '@/pages/platform/my-page/payment-methods/input-completed.vue';
import MyPagePaymentMethodInput from '@/pages/platform/my-page/payment-methods/input.vue';
import MyPagePaymentMethodList from '@/pages/platform/my-page/payment-methods/list.vue';
import MyPageProductsCancelCompleted from '@/pages/platform/my-page/products/cancel-completed.vue';
import MyPageProductsCancelConfirm from '@/pages/platform/my-page/products/cancel-confirm.vue';
import PlatformServiceEntryCompleted from '@/pages/platform/my-page/products/entry-completed.vue';
import PlatformServiceEntryInput from '@/pages/platform/my-page/products/entry-input.vue';
import PrivacyPolicy from '@/pages/platform/privacy-policy.vue';
import Terms from '@/pages/platform/terms.vue';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { PROPERTY_SEARCH_TYPE, TRANSITION_SOURCE_SERVICE, UA_TYPE } from '@/shared/const/service-type';
import { AccountUtilService } from '@/shared/services/account/account-util-service';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionCommonDataLoadService } from '@/shared/services/load/e-mansion-common-data-load-service';
import { SubAccountRoutingService } from '@/shared/services/route/sub-account-routing-service';
import { JudgeParameterDecisionProperty } from '@/shared/util/judge-parameter-decision-property';
import store from '@/store';

/** プラットフォーム ルーティング定義 */
const platformRouter = [
  {
    path: '/platform',
    name: 'ログイン後トップ',
    component: PlatformIndex,
    meta: {
      title: 'Portas | 会員専用トップページ | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: '会員専用トップページ',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (await AccountUtilService.isSubAccount()) {
        next({ path: await SubAccountRoutingService.getNextPathOnCallback(undefined) });
        return;
      }

      const isLoggedIn = await AuthService.isLoggedIn();
      // 会員ストアから会員ステータス情報を取得する
      const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];
      // 会員情報をStoreから取得
      const member: Member | null = store.getters['memberStore/member']; // beforeEach により、会員情報が取得される
      // OEM一覧情報をDBから取得
      await store.dispatch('oemsStore/oems');

      if (isLoggedIn && memberStatus && member) {
        await StoreExternalApiResponse.main();
        next();
        return;
      } else {
        next({ path: '/' });
        return;
      }
    },
  },
  {
    path: '/platform/terms',
    name: '利用規定',
    component: Terms,
    meta: {
      title: 'Portas | Portas利用規約 | ポルタス',
      description: 'Portas（ポルタス）Portas利用規約　アルテリアネットワークス株式会社',
      keywords: 'Portas利用規約',
      isPublic: true,
    },
  },
  {
    path: '/platform/privacy-policy',
    name: '個人情報の取り扱い',
    component: PrivacyPolicy,
    meta: {
      title: 'Portas | Portasにおける個人情報の取り扱いについて | ポルタス',
      description: 'Portas（ポルタス）Portasにおける個人情報の取り扱いについて　アルテリアネットワークス株式会社',
      keywords: 'Portasにおける個人情報の取り扱いについて',
      isPublic: true,
    },
  },
  {
    path: '/platform/maintenance',
    name: 'ただいまメンテナンス中です',
    component: PlatformMaintenance,
    meta: {
      title: 'ただいまメンテナンス中です',
      description: 'ただいまメンテナンス中です',
      keywords: 'メンテナンス',
    },
  },
  {
    path: '/platform/entry/input-email',
    name: 'Portas新規会員登録 お申し込み受付',
    component: PlatformEntryInputEmail,
    meta: {
      title: 'Portas | Portas新規会員登録 お申し込み受付 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: 'Portas新規会員登録お申し込み受付',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      const memberStatus: MemberStatus | undefined = store.getters['memberStore/memberStatus'];

      if (from.path === '/login-callback' && (!memberStatus || memberStatus.status === MEMBER_STATUS.ACCOUNT_CREATED || memberStatus.status === MEMBER_STATUS.MAIL_SENT)) {
        next();
        return;
      } else {
        next('/');
        return;
      }
    },
  },
  {
    path: '/platform/entry/accept-email',
    name: 'Portas新規会員登録 お申し込み受付完了',
    component: PlatformEntryAcceptEmail,
    meta: {
      title: 'Portas | Portas新規会員登録 お申し込み受付完了 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: 'Portas新規会員登録 お申し込み受付完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path === '/') {
        next('/');
        return;
      } else {
        next();
        return;
      }
    },
  },
  {
    path: '/platform/entry/input',
    name: 'Portas新規会員登録 お客様情報入力',
    component: PlatformEntryInput,
    meta: {
      title: 'Portas | Portas新規会員登録 お客様情報入力 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: 'Portas新規会員登録 お客様情報入力',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 確認画面から戻ったときだけ、入力内容が保持されるようにする
      if (from.path !== '/platform/entry/select-portas-service') {
        // platformEntryStoreで変更されるのは1つだけ
        store.commit('platformEntryStore/initEntryInputForm');
        // 物件検索によりストアに保存された値の初期化
        store.commit('platformEditStore/inputPropertyForm', null);
        store.commit('platformEditStore/searchPropertyForm', null);
      }

      // TODO: /platform/etnry/input に遷移するときだけ実行するように
      if (store.getters['oemsStore/oems'] === null) {
        await store.dispatch('oemsStore/oems');
      }

      const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];

      // 特殊物件でパラメータありで来た場合を想定
      if (memberStatus?.transitionSourceService && memberStatus?.transitionSourceServiceId) {
        // パラメータ判定テーブルにあるか確認する
        if (await JudgeParameterDecisionProperty.existsOnPortasDB(memberStatus?.transitionSourceServiceId)) {
          const TRANSITION_SOURCE_SERVICE_ID_REGEXP = {
            APARTMENT_ID: /^[0-9a-zA-Z]{6}$/,
            BUILDING_ID: /^[0-9]{13}$/,
          } as const;

          let searchType = -1;
          if (memberStatus?.transitionSourceService === TRANSITION_SOURCE_SERVICE.E_MANSION) {
            searchType = PROPERTY_SEARCH_TYPE.E_MANSION;
          } else if (memberStatus?.transitionSourceService === TRANSITION_SOURCE_SERVICE.UCOM) {
            if (TRANSITION_SOURCE_SERVICE_ID_REGEXP.APARTMENT_ID.test(memberStatus?.transitionSourceServiceId)) {
              searchType = PROPERTY_SEARCH_TYPE.E_MANSION;
            } else if (TRANSITION_SOURCE_SERVICE_ID_REGEXP.BUILDING_ID.test(memberStatus?.transitionSourceServiceId)) {
              searchType = PROPERTY_SEARCH_TYPE.UCOM;
            } else {
              throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.TRANSITION_SOURCE_INCONSISTENT);
            }
          } else if (memberStatus?.transitionSourceService === TRANSITION_SOURCE_SERVICE.FIVE_A) {
            searchType = PROPERTY_SEARCH_TYPE.E_MANSION;
          } else {
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.TRANSITION_SOURCE_INCONSISTENT);
          }

          const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(
            searchType,
            memberStatus.transitionSourceServiceId, // input-email.vueにて、物件テーブル.apartment_id の値に変換されて登録済み
            false
          );
          if ('errorMessage' in propertyList) {
            // 検索結果が11件以上の場合
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.OVER_SEARCH_PROPERTIES);
          } else if (propertyList.length === 0 || propertyList.length >= 2) {
            next();
            return;
          } else {
            if (propertyList[0].uaType === UA_TYPE.E_MANSION || propertyList[0].uaType === UA_TYPE.UCOM) {
              const member: Member | null = store.getters['memberStore/member'];

              // 会員テーブルにレコードが作成されていないとき、作成する
              if (!member) {
                const sub = await AuthService.getSub();

                const newMember: Member = new Member({
                  emailAddress: memberStatus.emailAddress,
                  memberStatus: 0,
                  primaryKeyAuth0: sub,
                  propertyId: propertyList[0].id,
                });

                const createdMember = await SpfApiService.createMember(newMember);
                if (!createdMember) {
                  throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
                }

                memberStatus.status = MEMBER_STATUS.ACCOUNT_REGISTERED;
                await store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: sub, memberStatus: memberStatus });

                await AuthService.refresh();
              }

              next({ path: '/platform/entry/completed' });
              return;
            } else if (propertyList[0].uaType === UA_TYPE.FIVE_A) {
              next();
              return;
            }
          }
        }
        next();
      } else {
        next();
      }
    },
  },
  {
    path: '/platform/entry/select-portas-service',
    name: 'Portas新規会員登録 Portas会員限定 お得なサービスのご案内',
    component: PlatformEntrySelectPortasService,
    meta: {
      title: 'Portas | Portas新規会員登録 Portas会員限定 お得なサービスのご案内 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: 'Portas新規会員登録 Portas会員限定 お得なサービスのご案内',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path === '/platform/entry/input' || from.path === '/platform/entry/confirm') {
        next();
        return;
      }
      next({ path: '/platform/entry/input' });
      return;
    },
  },
  {
    path: '/platform/entry/confirm',
    name: 'Portas新規会員登録・サービスお申し込み最終確認',
    component: PlatformEntryConfirm,
    meta: {
      title: 'Portas | Portas新規会員登録・サービスお申し込み最終確認 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: 'Portas新規会員登録・サービスお申し込み最終確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path === '/platform/entry/completed') {
        next({ path: '/platform' });
        return;
      } else if (
        from.path === '/platform/entry/input' ||
        from.path === '/platform/entry/select-portas-service' ||
        from.path === '/platform/payment-method-identity-verification-result'
      ) {
        next();
        return;

        // 再読み込みしたとき
      } else if (from.path === '/') {
        const member: Member | null = store.getters['memberStore/member'];

        // Portas会員作成済みのとき
        if (member) {
          next({ path: '/platform' });
          return;
        }

        // Portas会員未作成のとき
        next({ path: '/platform/entry/input' });
        return;
      }
      next({ path: '/platform/entry/input' });
      return;
    },
  },
  {
    path: '/platform/entry/completed',
    name: 'Portas新規会員登録 完了',
    component: PlatformEntryCompleted,
    meta: {
      title: 'Portas | Portas新規会員登録 完了 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: 'Portas新規会員登録 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      next();
      return;
    },
  },

  {
    path: '/platform/cancel/guide',
    name: '退会の手続き 案内',
    component: PlatformCancelGuide,
    meta: {
      title: 'Portas | Portas会員退会お申し込み | ポルタス',
      description: 'Portas（ポルタス）会員から退会をご検討中の方へ、退会お申し込み時の注意事項やお申し込みのお手続き方法についてご案内しています。',
      keywords: 'Portas会員退会お申し込み',
    },
  },
  {
    path: '/platform/cancel/confirm',
    name: '退会の手続き 確認',
    component: PlatformCancelConfirm,
    meta: {
      title: 'Portas | Portas会員退会お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）会員から退会をご検討中の方へ、退会お申し込み時の注意事項やお申し込みのお手続き方法についてご案内しています。',
      keywords: 'Portas会員退会お申し込み,確認',
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
      // 完了画面から戻った場合は会員専用トップへ遷移する
      if (from.path === '/platform/cancel/completed') {
        next({ path: '/platform' });
        return;
      }
      // 直前のURLが退会の手続き案内画面でなければ退会の手続き案内画面に遷移する
      if (from.path !== '/platform/cancel/guide') {
        next({ path: '/platform/cancel/guide' });
        return;
      }
      next();
    },
  },
  {
    path: '/platform/cancel/completed',
    name: '退会の手続き 退会完了',
    component: PlatformCancelCompleted,
    meta: {
      title: 'Portas | Portas会員退会お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）会員から退会をご検討中の方へ、退会お申し込み時の注意事項やお申し込みのお手続き方法についてご案内しています。',
      keywords: 'Portas会員退会お申し込み,完了',
      isPublic: true,
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      // 直前のURLが退会の手続き確認画面なら通す
      if (from.path === '/platform/cancel/confirm') {
        return next();
      }

      // 直前のURLが退会の手続き確認ではないならば手続きに戻す
      return next({ path: '/platform/cancel/guide' });
    },
  },
  {
    path: '/platform/cancel/impossible',
    name: '退会の手続き 退会不可',
    component: PlatformCancelImpossible,
    meta: {
      title: 'Portas | Portas会員退会 ご案内 | ポルタス',
      description: 'Portas（ポルタス）会員から退会をご検討中の方へ、退会お申し込み時の注意事項やお申し込みのお手続き方法についてご案内しています。',
      keywords: 'Portas会員からの退会お申し込み,ご案内',
    },
  },

  {
    path: '/platform/my-page',
    name: 'マイページ',
    component: MyPageIndex,
    meta: {
      title: 'Portas | マイページ | ポルタス',
      description: 'Portas（ポルタス）の会員情報をご確認いただけます。',
      keywords: 'マイページ',
    },
    /**
     * UCOM同時申込API の先の UCOMアカウント作成API が 非同期になっている関係で、DB と ストアの不整合が起きる可能性があるため
     * UCOMアカウント作成API を 同期処理にすると、処理時間の関係で AWS API gateway で タイムアウトが起きる可能性があり、ここで吸収
     *
     * また、Portas会員専用トップ から e-mansionサイト へ遷移して e-mansion新規会員登録 を実施したときも、
     * UCOMと同様に処理が非同期になっている関係で、DB と ストアの不整合が起きる可能性があり、ここで吸収
     */
    beforeEnter: async (_to: RouteLocationNormalized): Promise<void> => {
      await AuthService.refresh();

      if (await AuthService.isLinkedToUcom()) {
        const member: Member = store.getters['memberStore/member'];
        if (!member.primaryKeyUcom) {
          await store.dispatch('memberStore/memberStatus');
          await store.dispatch('memberStore/member');
        }
      } else if (await AuthService.isLinkedToEMansion()) {
        const member: Member = store.getters['memberStore/member'];
        if (!member.primaryKeyMye) {
          await store.dispatch('memberStore/memberStatus');
          await store.dispatch('memberStore/member');
          await EMansionCommonDataLoadService.loadInitialData(_to);
        }
      }
      await StoreExternalApiResponse.main();
    },
  },
  {
    path: '/platform/my-page/member-edit',
    name: '会員情報変更 入力',
    component: MyPageMemberEdit,
    meta: {
      title: 'Portas | マイページ 会員情報変更 入力 | ポルタス',
      description: 'Portas（ポルタス）の会員情報をご確認いただけます。',
      keywords: 'マイページ会員情報変更入力',
    },
    /** 遷移前のパスをstoreに保存する */
    beforeEnter: async (_to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (
        from.path === '/platform/my-page' ||
        from.path === '/platform/contacts' ||
        from.path === '/platform//my-page/products/entry-input' ||
        from.path === '/e-mansion/entry/input' ||
        from.path === '/e-mansion/entry/confirm' ||
        from.path === '/ucom/entry/input' ||
        from.path === '/5a/entry/input' ||
        from.path === '/5a/entry/confirm'
      ) {
        store.commit('platformEditStore/beforePath', from.path);
      }

      // 確認画面から戻ったときだけ、入力内容が保持されるようにする
      if (from.path !== '/platform/my-page/member-confirm') {
        store.commit('platformEditStore/editInputForm', null);
        store.commit('platformEditStore/property', null);
        store.commit('platformEditStore/myPageProperty', null);
        store.commit('platformEditStore/inputPropertyForm', null);
        store.commit('platformEditStore/searchPropertyForm', null);
      }

      if (store.getters['oemsStore/oems'] === null) {
        await store.dispatch('oemsStore/oems');
      }

      next();
    },
  },
  {
    path: '/platform/my-page/member-confirm',
    name: '会員情報変更 確認',
    component: MyPageMemberConfirm,
    meta: {
      title: 'Portas | マイページ 会員情報変更 確認 | ポルタス',
      description: 'Portas（ポルタス）の会員情報をご確認いただけます。',
      keywords: 'マイページ会員情報変更確認',
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
      if (from.path !== '/platform/my-page/member-edit') {
        next({ path: '/platform' });
        return;
      } else {
        store.commit('platformEditStore/beforePath', from.path);
      }
      next();
    },
  },
  {
    path: '/platform/my-page/member-completed',
    name: '会員情報変更 完了',
    component: MyPageMemberCompleted,
    meta: {
      title: 'Portas | マイページ 会員情報変更 完了 | ポルタス',
      description: 'Portas（ポルタス）の会員情報をご確認いただけます。',
      keywords: 'マイページ会員情報変更完了',
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
      if (
        from.path === '/platform/my-page/member-confirm' ||
        from.path === '/e-mansion/entry/input' ||
        from.path === '/e-mansion/entry/confirm' ||
        from.path === '/ucom/entry/input' ||
        from.path === '/5a/entry/input' ||
        from.path === '/5a/entry/confirm'
      ) {
        store.commit('platformEditStore/beforePath', from.path);
      } else {
        next({ path: '/platform' });
        return;
      }
      next();
    },
  },
  {
    path: '/platform/my-page/payment-method/list',
    name: 'Portasサービスお支払い方法',
    component: MyPagePaymentMethodList,
    meta: {
      title: 'Portas | Portasサービスお支払い方法 | ポルタス',
      description: 'Portas（ポルタス）へ登録されたお支払い方法を表示します。',
      keywords: 'Portasサービスお支払い方法',
    },
  },
  {
    path: '/platform/my-page/payment-method/input',
    name: 'Portasサービス お支払い方法の登録',
    component: MyPagePaymentMethodInput,
    meta: {
      title: 'Portas | Portasサービス お支払い方法の登録 | ポルタス',
      description: 'Portas（ポルタス）のお支払い方法をご確認いただけます。',
      keywords: 'Portasサービス お支払い方法の登録',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (!(from.path === '/platform/my-page/payment-method/list' || from.path === '/platform/payment-method-identity-verification-result')) {
        // 正しい遷移先以外からの遷移は許可しない
        next({ path: '/platform/my-page/payment-method/list' }); //一覧に戻す
        return;
      } else if (from.path === '/platform/my-page/payment-method/list') {
        // 支払方法一覧画面から遷移したときのみ、エラーメッセージを削除する
        store.commit('errorMessageStore/errorMessages', null);
        store.commit('errorMessageStore/errorMessageTitle', null);
      }

      const isLoggedIn = await AuthService.isLoggedIn();
      // 会員ストアから会員ステータス情報を取得する
      const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];
      // 会員情報をStoreから取得
      const member: Member | null = store.getters['memberStore/member']; // beforeEach により、会員情報が取得される

      if (isLoggedIn && memberStatus && member) {
        await StoreExternalApiResponse.main();
        next();
        return;
      } else {
        next({ path: '/' });
        return;
      }
    },
  },
  {
    path: '/platform/my-page/payment-method/edit',
    name: 'Portasサービスお支払い方法の変更',
    component: MyPagePaymentMethodEdit,
    meta: {
      title: 'Portas | Portasサービスお支払い方法の変更 | ポルタス',
      description: 'Portas（ポルタス）のお支払い方法を変更いただけます。',
      keywords: 'Portasサービスお支払い方法の変更',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/platform/my-page/payment-method/list') {
        // 正しい遷移先以外からの遷移は許可しない
        next({ path: '/platform/my-page/payment-method/list' }); //一覧に戻す
        return;
      } else if (from.path === '/platform/my-page/payment-method/list') {
        // 支払方法一覧画面から遷移したときのみ、エラーメッセージを削除する
        store.commit('errorMessageStore/errorMessages', null);
        store.commit('errorMessageStore/errorMessageTitle', null);
      }
      next();
    },
  },
  {
    path: '/platform/my-page/payment-method/delete-confirm',
    name: 'Portasサービス お支払い方法の削除',
    component: MyPagePaymentMethodDeleteConfirm,
    meta: {
      title: 'Portas | Portasサービス お支払い方法の削除 | ポルタス',
      description: 'Portas（ポルタス）のお支払い方法を削除いただけます。',
      keywords: 'Portasサービス お支払い方法の削除',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/platform/my-page/payment-method/list') {
        // 正しい遷移先以外からの遷移は許可しない
        next({ path: '/platform/my-page/payment-method/list' }); //一覧に戻す
        return;
      }
      next();
    },
  },
  {
    path: '/platform/my-page/payment-method/input-completed',
    name: 'Portasサービス お支払い方法の登録完了',
    component: MyPagePaymentMethodInputCompleted,
    meta: {
      title: 'Portas | Portasサービス お支払い方法の登録完了 | ポルタス',
      description: 'Portas（ポルタス）のお支払い方法をご確認いただけます。',
      keywords: 'Portasサービス お支払い方法の登録完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (
        from.path === '/platform/my-page/payment-method/edit' ||
        from.path === '/platform/my-page/payment-method/input' ||
        from.path === '/platform/payment-method-identity-verification-result'
      ) {
        next();
        return;
      }
      // 正しい遷移先以外からの遷移は許可しない
      next({ path: '/platform/my-page/payment-method/list' }); //一覧に戻す
    },
  },
  {
    path: '/platform/my-page/payment-method/delete-completed',
    name: 'Portasサービス お支払い方法の削除完了',
    component: MyPagePaymentMethodDeleteCompleted,
    meta: {
      title: 'Portas | Portasサービス お支払い方法の削除完了 | ポルタス',
      description: 'Portas（ポルタス）のお支払い方法をご確認いただけます。',
      keywords: 'Portasサービス お支払い方法の削除完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/platform/my-page/payment-method/delete-confirm') {
        // 正しい遷移先以外からの遷移は許可しない
        next({ path: '/platform/my-page/payment-method/list' }); //一覧に戻す
        return;
      }
      next();
    },
  },
  {
    path: '/platform/payment-method-identity-verification-result',
    name: 'お支払方法本人認証結果取得画面',
    component: PaymentMethodIdentityVerificationResult,
    meta: {
      title: 'Portas | お支払方法本人認証結果取得画面 | ポルタス',
      description: 'お支払方法本人認証結果取得画面',
      keywords: 'お支払方法本人認証結果取得画面',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      next();
    },
  },
  {
    path: '/platform/my-page/products/cancel-confirm',
    name: 'サービス解約お申し込み 確認',
    component: MyPageProductsCancelConfirm,
    meta: {
      title: 'Portas | マイページ サービス解約お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）で購入した商品の解約をご確認いただけます。',
      keywords: 'サービス解約お申し込み 確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/platform/my-page') {
        next({ path: '/platform/my-page' });
        return;
      }
      next();
    },
  },
  {
    path: '/platform/my-page/products/cancel-completed',
    name: 'サービス解約お申し込み 完了',
    component: MyPageProductsCancelCompleted,
    meta: {
      title: 'Portas | マイページ サービス解約お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）で購入した商品の解約をご確認いただけます。',
      keywords: 'サービス解約お申し込み 完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/platform/my-page/products/cancel-confirm') {
        next({ path: '/platform/my-page' });
        return;
      }
      next();
    },
  },
  {
    path: '/platform/my-page/billing-history/list',
    name: 'ご請求履歴一覧',
    component: MyPageBillingHistoryList,
    meta: {
      title: 'Portas | マイページ 料金明細 | ポルタス',
      description: 'Portas（ポルタス）からのご請求情報の一覧をご覧いただけます。',
      keywords: 'ご請求履歴一覧',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      await StoreExternalApiResponse.main();
      next();
    },
  },
  {
    path: '/platform/my-page/products/entry-input',
    name: 'Portasサービス お申し込み内容の最終確認',
    component: PlatformServiceEntryInput,
    meta: {
      title: 'Portas | Portasサービス お申し込み内容の最終確認 | ポルタス',
      description: 'Portas（ポルタス）にお申し込みされるサービスをご確認いただけます。',
      keywords: 'Portasサービス お申し込み内容の最終確認',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      /**
       * TODO: 原因調査
       *
       * from.path が４パターンある
       *
       * パターン1: from.path: /platform
       * パターン2: from.path: /platform/
       * パターン3: from.path: /platform/payment-method-identity-verification-result
       * パターン4: fron.path: Connectix申し込み完了画面(UCOM,e-mansion,Five.A,Five.A)
       * memo:
       * 選んだ商品は関係ないっぽい。
       * 画面更新してラジオボタン選択して画面遷移する場合は、パターン1
       * 一度お申し込み画面行って戻るボタンから戻ってきて選択するとパターン2
       * お支払方法本人認証結果取得画面から異常系で戻ってくる場合は、パターン3
       */
      if (
        !(
          from.path === '/platform/' ||
          from.path === '/platform' ||
          from.path === '/platform/payment-method-identity-verification-result' ||
          from.path === '/connectix/ucom/completed' ||
          from.path === '/connectix/e-mansion/completed' ||
          from.path === '/5a/entry/completed' ||
          from.path === '/connectix/5a/completed'
        )
      ) {
        // 正しい遷移先以外からの遷移は許可しない
        next({ path: '/platform' }); //会員専用トップページに戻す
        return;
      } else if (
        from.path === '/platform/' ||
        from.path === '/platform' ||
        from.path === '/connectix/ucom/completed' ||
        from.path === '/connectix/e-mansion/completed' ||
        from.path === '/5a/entry/completed' ||
        from.path === '/connectix/5a/completed'
      ) {
        // 会員画面からサービスを選んで遷移したときと、Connectix完了画面から遷移したときは、エラーメッセージを削除する
        store.commit('errorMessageStore/errorMessages', null);
        store.commit('errorMessageStore/errorMessageTitle', null);
        next();
        return;
      }
      next();
      return;
    },
  },
  {
    path: '/platform/my-page/products/entry-completed',
    name: 'Portasサービス お申し込み完了',
    component: PlatformServiceEntryCompleted,
    meta: {
      title: 'Portas | Portasサービス お申し込み完了| ポルタス',
      description: 'Portas（ポルタス）にお申し込みされるサービスをご確認いただけます。',
      keywords: 'Portasサービス お申し込み完了',
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/platform/my-page/products/entry-input' && from.path !== '/platform/payment-method-identity-verification-result') {
        next({ path: '/platform' });
        return;
      }
      next();
    },
  },
];

export default platformRouter;
